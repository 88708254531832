$rt-namespace: "Toastify";
$rt-toast-width: 480px !default;
$rt-toast-background: #fff !default;
$rt-toast-min-height: 56px !default;
$rt-toast-max-height: 800px !default;

$rt-color-info: var(--color-base-primary-blue10) !default;
$rt-color-info-high: var(--color-base-primary-blue500) !default;
$rt-color-success: var(--color-base-green10) !default;
$rt-color-success-high: var(--color-base-green700) !default;
$rt-color-warning: var(--color-base-yellow10) !default;
$rt-color-warning-high: var(--color-base-yellow800) !default;
$rt-color-error: var(--color-base-red10) !default;
$rt-color-error-high: var(--color-base-red600) !default;

$rt-text-color-info: var(--color-base-primary-blue600) !default;
$rt-text-color-success: var(--color-base-green700) !default;
$rt-text-color-warning: var(--color-base-yellow800) !default;
$rt-text-color-error: var(--color-base-red700) !default;

$rt-mobile: "only screen and (max-width : 480px)" !default;
$rt-z-index: 9999 !default;
