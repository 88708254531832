@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
body > div,
body > div > div {
  @apply h-full;
  @apply font-normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hide arrows for input type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/* Sidebar Scrollbar */
.sidebar ::-webkit-scrollbar {
  width: 5px;
}
.sidebar ::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, 0.4);
}
.sidebar ::-webkit-scrollbar-thumb {
  background-color: #a0aec0;
  border-radius: 8px;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *))::before {
  content: "";
  padding: 0 2px;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *))::after {
  content: "";
  padding: 0 2px;
}
