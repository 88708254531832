/**
 * Do not edit directly
 * Generated on Tue, 25 Oct 2022 08:29:30 GMT
 */

:root {
  --color-base-blue-gray5: #fcfcfd;
  --color-base-blue-gray10: #f8f9fa;
  --color-base-blue-gray25: #f3f5f7;
  --color-base-blue-gray50: #ebeff2;
  --color-base-blue-gray100: #e3e8ec;
  --color-base-blue-gray200: #d7dee3;
  --color-base-blue-gray300: #d7dee3;
  --color-base-blue-gray400: #c8d3db;
  --color-base-blue-gray500: #8fa2b0;
  --color-base-blue-gray600: #50697d;
  --color-base-blue-gray700: #2f4c63;
  --color-base-blue-gray800: #133650;
  --color-base-blue-gray900: #03243d;
  --color-base-green10: #edf8f1;
  --color-base-green25: #e8f6ee;
  --color-base-green50: #e0f3e8;
  --color-base-green100: #c2e8d1;
  --color-base-green200: #a3dcba;
  --color-base-green300: #84d0a2;
  --color-base-green400: #66c58b;
  --color-base-green-fonoa: #55d287;
  --color-base-primary-white: #ffffff;
  --color-base-green500: #50b83c;
  --color-base-green600: #43a630;
  --color-base-green700: #108043;
  --color-base-green800: #2c7448;
  --color-base-green900: #1a462b;
  --color-base-primary-blue10: #ecf1fc;
  --color-base-primary-blue25: #e5ecfb;
  --color-base-primary-blue50: #dce6f9;
  --color-base-primary-blue100: #b9cdf3;
  --color-base-primary-blue200: #96b4ee;
  --color-base-primary-blue300: #739be8;
  --color-base-primary-blue400: #5082e2;
  --color-base-primary-blue500: #2d69dc;
  --color-base-primary-blue600: #245cc8;
  --color-base-primary-blue700: #2658b8;
  --color-base-primary-blue800: #171f46;
  --color-base-primary-blue900: #08152d;
  --color-base-purple10: #f4edff;
  --color-base-purple25: #f1e9ff;
  --color-base-purple50: #ede1ff;
  --color-base-purple100: #dac4ff;
  --color-base-purple200: #c8a6ff;
  --color-base-purple300: #b688ff;
  --color-base-purple400: #a36bff;
  --color-base-purple500: #914dff;
  --color-base-purple600: #6f27e2;
  --color-base-purple700: #5213b7;
  --color-base-purple800: #330085;
  --color-base-purple900: #190042;
  --color-base-red10: #fceae7;
  --color-base-red25: #fce5e1;
  --color-base-red50: #fbddd7;
  --color-base-red100: #f6bab0;
  --color-base-red200: #f29888;
  --color-base-red300: #ee7660;
  --color-base-red400: #ea5338;
  --color-base-red500: #de3618;
  --color-base-red600: #d22e11;
  --color-base-red700: #bf1d08;
  --color-base-red800: #6f1b0c;
  --color-base-red900: #2c0b05;
  --color-base-tangerine10: #fff5f0;
  --color-base-tangerine25: #fff3ec;
  --color-base-tangerine50: #ffefe6;
  --color-base-tangerine100: #fedecd;
  --color-base-tangerine200: #feceb4;
  --color-base-tangerine300: #fdbe9b;
  --color-base-tangerine400: #fba474;
  --color-base-tangerine500: #eb7f01;
  --color-base-tangerine600: #e1814c;
  --color-base-tangerine700: #b6531c;
  --color-base-tangerine800: #5a3000;
  --color-base-tangerine900: #2d1800;
  --color-base-teal10: #f8feff;
  --color-base-teal25: #ecfcff;
  --color-base-teal50: #e6f8fc;
  --color-base-teal100: #ccf2f8;
  --color-base-teal200: #99e4f1;
  --color-base-teal300: #66d7eb;
  --color-base-teal400: #33c9e4;
  --color-base-teal500: #00bcdd;
  --color-base-teal600: #0096b1;
  --color-base-teal700: #007185;
  --color-base-teal800: #004b58;
  --color-base-teal900: #00262c;
  --color-base-yellow10: #fffae4;
  --color-base-yellow25: #fff9dd;
  --color-base-yellow50: #fff7d2;
  --color-base-yellow100: #ffeea4;
  --color-base-yellow200: #ffe677;
  --color-base-yellow300: #ffdd4a;
  --color-base-yellow400: #ffd51c;
  --color-base-yellow500: #eec200;
  --color-base-yellow600: #e7bd07;
  --color-base-yellow700: #c09e06;
  --color-base-yellow800: #7a6a12;
  --color-base-yellow900: #302700;
}
