.react-autosuggest__container {
  width: 538px;
  position: relative;
}

.react-autosuggest-input {
  width: 100%;
  height: 40px;
}

/* Hide input type="search" default browser styling, not to interfere with loading animation */
.react-autosuggest-input::-webkit-search-decoration,
.react-autosuggest-input::-webkit-search-cancel-button,
.react-autosuggest-input::-webkit-search-results-button,
.react-autosuggest-input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
.react-autosuggest-input::-ms-clear,
.react-autosuggest-input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 43px;
  width: 538px;
  background-color: #fff;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  border-radius: 2px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion:nth-child(odd) {
  background-color: rgba(230, 237, 241, 0.24);
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: rgba(37, 104, 233, 0.12) !important;
}

.react-autosuggest-icon {
  position: absolute;
  top: 14px;
  left: 10px;
}

.react-autosuggest-highlight {
  font-weight: 600;
}
