// Override react-colorful default theme

.react-colorful {
  width: 100% !important;
  height: 220px !important;
}

.react-colorful__saturation {
  border-radius: 6px !important;
  margin-bottom: 12px;
}

.react-colorful__last-control {
  border-radius: 6px !important;
  height: 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.react-colorful__pointer {
  width: 24px !important;
  height: 24px !important;
  border: 6px solid #fff !important;
  box-shadow: none !important;

  &:before {
    content: "";
    position: absolute;
    top: -7px;
    left: -7px;
    border-radius: 26px;
    width: 26px;
    height: 26px;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  &:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 12px;
    width: 12px;
    height: 12px;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}
