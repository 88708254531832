input[type="radio"]:checked + label #radio-span {
  background-color: var(--color-base-primary-blue500);
  border: 1px solid var(--color-base-primary-blue500);
  padding: 5px;
  box-shadow: 0px 0px 0px 3px white inset;
}

input[type="radio"]:checked {
  color: #2d69dc;
  border: 1px solid var(--color-base-primary-blue500);
}
